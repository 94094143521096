import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type { UiState } from "instantsearch.js";
import { get, values } from "lodash/fp";
import { useTranslation } from "next-i18next";
import React, { ReactElement } from "react";
import { useInstantSearch } from "react-instantsearch";

import { useContent } from "../ContentProvider";

type Props = {
  variant?: "widget";
};

const TopBar = ({ variant }: Props): ReactElement => {
  const { country: contentCountry, countries, host } = useContent();
  const { t } = useTranslation();
  const theme = useTheme();
  let uiState: UiState;
  try {
    uiState = useInstantSearch().uiState;
  } catch {
    uiState = undefined;
  }

  const searchCountryName = get(
    "hierarchicalMenu['locations.lvl0'][0]",
    values(uiState)[0],
  );
  const country =
    countries.find(({ name }) => searchCountryName === name) ?? contentCountry;

  if (variant === "widget" && country?.emergencyNumber == null) {
    return <></>;
  }

  return (
    <AppBar
      sx={{
        backgroundColor: "background.banner",
        boxShadow:
          host && !host.enableBannerBoxShadow ?
            "none"
          : "0px 4px 8px rgba(0, 0, 0, 0.05)",
      }}
      position="static"
      elevation={0}
    >
      <Container>
        <Toolbar
          sx={{
            display: "grid",
            gap: 2,
            px: 0,
            py: 1,
            [theme.breakpoints.down("sm")]: {
              gap: 1,
            },
            // Fix for left and right padding, to ensure this overrides the default small and up media query.
            [theme.breakpoints.up("xs")]: {
              px: 0,
            },
            ...(country && country.emergencyNumber ?
              variant === "widget" ?
                {
                  justifyContent: "center",
                  gridTemplateColumns: "auto auto",
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                    gap: 1,
                  },
                }
              : {
                  gridTemplateColumns: "1fr auto auto",
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "flex-start",
                    gap: 1,
                  },
                }
            : {
                gridTemplateColumns: "1fr auto",
                gap: 1,
              }),
          }}
        >
          {country?.emergencyNumber ?
            <>
              <Box
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    gridColumn: "1 / span 2",
                    alignSelf: "center",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "text.primary",
                    minWidth: "80px",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.9rem",
                    },
                  }}
                >
                  {t("Are you or someone else in immediate danger?")}
                </Typography>
              </Box>
              <Box
                sx={
                  variant == "widget" ?
                    {
                      [theme.breakpoints.down("sm")]: {
                        gridColumn: "1 / span 2",
                      },
                    }
                  : null
                }
              >
                <Button
                  variant="contained"
                  color="error"
                  fullWidth={variant !== "widget"}
                  href={`tel:${country.emergencyNumber}`}
                  target="_parent"
                  rel="noopener noreferrer"
                  data-testid="emergencyServicesButton"
                >
                  <Hidden smUp implementation="css">
                    {t("Call {{emergencyNumber}}", {
                      emergencyNumber: country.emergencyNumber,
                    })}
                  </Hidden>
                  <Hidden smDown implementation="css">
                    {t("Emergency Services")}
                  </Hidden>
                </Button>
              </Box>
            </>
          : <Box>
              <Typography
                sx={{
                  color: "text.primary",
                  minWidth: "80px",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.9rem",
                  },
                }}
              >
                {t("Need to leave quickly?")}
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.8rem",
                  },
                }}
              >
                {t("Click to immediately exit this site.")}
              </Typography>
            </Box>
          }
          {variant !== "widget" && (
            <Button
              variant="contained"
              color="error"
              fullWidth
              href="https://accuweather.com"
              target="_parent"
              rel="noopener noreferrer"
              data-testid="leaveQuicklyButton"
            >
              {t("Quick Exit")}
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
