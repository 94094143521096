const English: string = "en";

const baseLocale = (locale: string): string => {
  if (!locale) {
    return locale;
  }
  let baseLocale = locale;
  if (locale.includes("-")) {
    const [firstPart, secondPart] = locale.split("-");
    baseLocale =
      firstPart?.toLowerCase() === secondPart?.toLowerCase() ?
        firstPart
      : locale;
  }
  return baseLocale;
};

export { baseLocale, English };
