import {
  AppBar,
  Box,
  Container,
  Hidden,
  Link,
  SxProps,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import Image from "next/legacy/image";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import React, { ReactElement, ReactNode } from "react";

import { useContent } from "../ContentProvider";

type Props = {
  children?: ReactNode;
  variant?: "widget" | "minimal" | "white";
};

const NavBar = ({ children, variant }: Props): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { host } = useContent();
  const showBranding = host ? host.widgetBrandingVisible : true;
  const logoWidth =
    host?.logo ? host.logo.width / (host.logo.height / 33) : 200;

  const noRightPadding: SxProps<Theme> =
    variant !== "widget" || showBranding ?
      {}
    : {
        pr: 0,
        [theme.breakpoints.up("lg")]: {
          maxWidth: "100%",
        },
        [theme.breakpoints.up("xs")]: {
          pr: 0,
        },
      };

  return (
    <AppBar
      sx={{
        py: 1,
        backgroundColor:
          variant === "white" ? "background.paper"
          : variant === "widget" ? "background.default"
          : "transparent",
      }}
      position="static"
      elevation={0}
    >
      <Container
        sx={{
          ...noRightPadding,
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "minmax(auto, 200px) 1fr auto",
          gap: 1,
        }}
      >
        <Box sx={{ justifySelf: logoWidth < 200 ? "start" : null }}>
          {variant !== "minimal" && (
            <>
              {variant === "widget" ?
                <>
                  {showBranding ?
                    <>
                      {host?.logo ?
                        <Box
                          sx={{
                            width: logoWidth < 200 ? logoWidth : "auto",
                          }}
                        >
                          <Image
                            layout="responsive"
                            alt={host.name}
                            src={host.logo.url}
                            width={logoWidth}
                            height={33}
                            priority
                          />
                        </Box>
                      : <Image
                          layout="responsive"
                          alt={t("Find A Helpline logo")}
                          src="/findahelpline-logo.svg"
                          width={200}
                          height={33}
                          priority
                        />
                      }
                    </>
                  : <></>}
                </>
              : <NextLink
                  legacyBehavior
                  href="/"
                  passHref
                  prefetch={process.env.NODE_ENV === "production"}
                >
                  <Link aria-label="Find A Helpline">
                    {host?.logo ?
                      <Box
                        sx={{
                          width: logoWidth < 200 ? logoWidth : "auto",
                        }}
                      >
                        <Image
                          layout="responsive"
                          alt={host.name}
                          src={host.logo.url}
                          width={logoWidth}
                          height={33}
                          priority
                        />
                      </Box>
                    : <Image
                        layout="responsive"
                        alt={t("Find A Helpline logo")}
                        src="/findahelpline-logo.svg"
                        width={200}
                        height={33}
                        priority
                      />
                    }
                  </Link>
                </NextLink>
              }
            </>
          )}
        </Box>
        <Box>
          {variant === "widget" && showBranding && (
            <Hidden smDown>
              <Typography
                sx={{
                  color: "secondary.dark",
                  ml: 3,
                }}
              >
                {t(
                  "Struggling? Get free, confidential support from a real person.",
                )}
              </Typography>
            </Hidden>
          )}
        </Box>
        <Box>{children}</Box>
      </Container>
    </AppBar>
  );
};

export default NavBar;
