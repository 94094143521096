import { Backdrop, Box, Slide } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactElement, ReactNode, useState } from "react";

type Props = {
  children?: ReactNode;
  open?: boolean;
  onClose?: (event: object, reason: "backdropClick" | "escapeKeyDown") => void;
  variant?: "persistent" | "floating";
};

const Drawer = ({
  children,
  open,
  variant = "persistent",
  onClose,
  ...rest
}: Props): ReactElement => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Backdrop
      invisible={variant == "floating"}
      sx={{
        display: "block",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        top: 0,
        overflow: "auto",
        position: "absolute",
      }}
      open={onClose !== null ? open : isOpen}
      onClick={(): void => {
        onClose ? onClose({}, "backdropClick") : setIsOpen(false);
      }}
      onKeyDown={(event: React.KeyboardEvent): void => {
        if (
          event.key.toLowerCase() === "escape" ||
          event.key.toLowerCase() === "esc"
        ) {
          onClose ? onClose(event, "escapeKeyDown") : setIsOpen(false);
        }
      }}
      {...rest}
    >
      <Slide direction="left" in={open} mountOnEnter>
        <Box
          sx={{
            width: "30%",
            marginLeft: "auto",
            height: "100%",
            background: theme.palette.background.paper,
            overflowY: "auto",
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            "&::-webkit-scrollbar": { display: "none" },
            [theme.breakpoints.down("md")]: {
              width: "80%",
            },
            ...(variant === "floating" && {
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
            }),
          }}
          onClick={(e): void => e.stopPropagation()}
        >
          {children}
        </Box>
      </Slide>
    </Backdrop>
  );
};

export default Drawer;
