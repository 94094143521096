import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  Box,
  Button,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { capitalize } from "lodash";
import { useRouter } from "next/compat/router";
import { useTranslation } from "next-i18next";
import React, { ReactElement, useState } from "react";

import { baseLocale } from "../../util/baseLocale";
import { useContent } from "../ContentProvider";
import Drawer from "../Drawer";

type LanguageSwitcherProps = {
  /**
   * Defines the appearance of the language switcher.
   * - `standard`: The default appearance, which should be used on the homepage. It utilises the Material UI `Drawer` component and should not be used in the widget context.
   * - `secondary`: A secondary appearance, which should be used on all other pages.
   * - `contained`: A version of the switcher that is contained within the widget.
   * - `floating`: A version of the switcher that is contained within the widget but has no backdrop/overlay.
   */
  variant?: "standard" | "secondary" | "contained" | "floating";
};

const LanguageSwitcher = ({
  variant = "standard",
}: LanguageSwitcherProps): ReactElement => {
  const theme = useTheme();
  const router = useRouter();
  const { host } = useContent();
  const [isOpen, setIsOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { language: currentLocale } = i18n;
  const locales = router?.locales ?? [currentLocale ?? "en"];
  const onOpen = (): void => setIsOpen(true);
  const onClose = (): void => setIsOpen(false);
  const onChange = (locale: string) => {
    return () => {
      const path = router.asPath;
      router.push(path, path, { locale });
      onClose();
    };
  };

  const button =
    variant == "standard" ?
      <Button
        onClick={onOpen}
        size="small"
        variant="contained"
        color="menu"
        name="Language"
        aria-label={t("Language")}
        startIcon={<TranslateIcon />}
        data-testid="languageButton"
        sx={{
          ml: 1,
          width: 32,
          height: 32,
          minWidth: 32,
          "& .MuiButton-startIcon": {
            mx: 1,
          },
        }}
      />
    : <Button
        sx={{
          ml: 1,
          [theme.breakpoints.down("lg")]: {
            padding: "6px",
            width: 42,
            minWidth: 42,
            ml: 1,
            mr: 0,
          },
          "& .MuiButton-startIcon": {
            [theme.breakpoints.down("lg")]: {
              margin: 0,
            },
          },
          "& .MuiTypography-root": {
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          },
          //background: "#FFFFFF",
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
        }}
        variant="contained"
        color="language"
        onClick={onOpen}
        name="Language"
        aria-label={t("Language")}
        startIcon={<TranslateIcon />}
        data-testid="languageButton"
      >
        <Typography>
          {capitalize(
            new Intl.DisplayNames([currentLocale ?? "en"], {
              type: "language",
            }).of(baseLocale(currentLocale) ?? "en"),
          )}
        </Typography>
      </Button>;

  const content = (
    <Container>
      <Box
        sx={{
          display: "flex",
          color: "text.primary",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <SvgIcon sx={{ mr: 1 }}>
            <TranslateIcon />
          </SvgIcon>
          <Typography
            variant="h5"
            sx={{
              fontFamily: (theme) => theme.typography.fontFamily,
            }}
          >
            {t("Language")}
          </Typography>
        </Box>
        <Button
          sx={{
            pl: 2,
            pr: 2,
          }}
          onClick={onClose}
          endIcon={<CloseIcon />}
          name="Close"
          aria-label={t("Close")}
          variant="contained"
          color="inherit"
          data-testid="closeButton"
        >
          {t("Close")}
        </Button>
      </Box>
      <List
        component="nav"
        aria-label="language list"
        data-testid="languageList"
      >
        {locales.map((locale) => (
          <ListItemButton
            key={locale}
            onClick={onChange(locale)}
            sx={{ minWidth: "32px" }}
          >
            <ListItemIcon
              sx={{
                color:
                  host?.useDefaultLinkStyles ? "text.primary" : (
                    "secondary.main"
                  ),
              }}
            >
              {locale === currentLocale && <CheckIcon />}
            </ListItemIcon>
            <ListItemText
              primary={capitalize(
                new Intl.DisplayNames([locale], { type: "language" }).of(
                  baseLocale(locale),
                ),
              )}
              primaryTypographyProps={{
                color:
                  locale === currentLocale && !host?.useDefaultLinkStyles ?
                    "secondary"
                  : "textPrimary",
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </Container>
  );

  return (
    <>
      {button}
      {variant === "standard" || variant === "secondary" ?
        <MuiDrawer
          data-testid="languageDrawer"
          sx={{
            "& > .MuiPaper-root": {
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
              width: "30%",
              [theme.breakpoints.down("sm")]: {
                width: "80%",
              },
            },
          }}
          anchor="right"
          open={isOpen}
          onClose={onClose}
          variant="temporary"
          ModalProps={{
            keepMounted: true,
            disablePortal: true,
          }}
        >
          {content}
        </MuiDrawer>
      : <Drawer
          open={isOpen}
          variant={variant == "floating" ? "floating" : "persistent"}
          onClose={onClose}
          data-testid="languagesBackdrop"
        >
          {content}
        </Drawer>
      }
    </>
  );
};

export default LanguageSwitcher;
